import "./App.css";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import Loader from "./Loader";

function App() {
  const componentRef = useRef();

  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get("name");
  const size = urlParams.get("size");
  const storeId = urlParams.get("sellerId");
  const orderId = urlParams.get("orderId");

  const valid = size && name && storeId && orderId;

  return valid ? (
    <div className="App">
      <div
        style={{
          display: "none",
        }}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              minHeight: "100vh",
              fontSize: "1.25rem",
            }}
            ref={componentRef}
            className={`flex al-ce jc-ce col`}
          >
            <div className="flex jc-ce al-ce">
              <img
                style={{
                  width: "100%",
                }}
                src={`https://chart.googleapis.com/chart?chl=${orderId}&cht=qr&chs=500x500&choe=utf8`}
                alt="qr"
              />
            </div>
            <div
              style={{
                textTransform: "capitalize",
                textAlign: "center",
              }}
            >
              Name: {name}
            </div>
            <div
              style={{
                textAlign: "center",
                margin: ".5rem 0",
              }}
            >
              Size: {size}
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              Seller Store ID: {storeId}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "100vh",
        }}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "calc(100vh - 100px)",
              overflowY: "auto",
              backgroundColor: "white",
              fontSize: "1.25rem",
            }}
            className={`flex al-ce jc-ce col`}
          >
            <div className="flex jc-ce al-ce">
              <img
                style={{
                  width: "100%",
                }}
                src={`https://chart.googleapis.com/chart?chl=${orderId}&cht=qr&chs=500x500&choe=utf8`}
                alt="qr"
              />
            </div>
            <div
              style={{
                textTransform: "capitalize",
                textAlign: "center",
              }}
            >
              Name: {name}
            </div>
            <div
              style={{
                textAlign: "center",
                margin: ".5rem 0",
              }}
            >
              Size: {size}
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              Seller Store ID: {storeId}
            </div>
          </div>
        </div>
        <ReactToPrint
          bodyClass="qrprint"
          trigger={() => <div className={"btn"}>Print</div>}
          content={() => componentRef.current}
        />
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Loader />
    </div>
  );
}

export default App;
